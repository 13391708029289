import React, { useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import OriginContainer from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { formatDate, getDateByTimeZone } from "@/lib/date";
import { useQnasContext } from "@/store";

type FormData = {
  answer: string;
};

const Container = styled(OriginContainer)`
  margin-bottom: 100px;
`;

const ContentContainer = styled(OriginContainer)`
  padding-left: 15px;
  margin-bottom: 20px;
`;

const Pre = styled.pre`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
`;

const Title = styled.h1`
  padding-left: 15px;
`;

export default () => {
  const { state, ...actions } = useQnasContext();
  const { handleSubmit, reset, register } = useForm<FormData>();
  let { slug } = useParams<{ slug: string }>();

  const history = useHistory();

  useEffect(() => {
    actions.fetchById(slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      answer: state.qnaDetail.answer,
    });
  }, [reset, state.qnaDetail.answer]);

  const onSubmit = handleSubmit(({ answer }) => {
    actions
      .update(state.qnaDetail.id, { answer })
      .then((res) => {
        history.push("/qnas");
        actions.fetchAll();
      })
      .catch((error) => {
        window.alert(error.message);
      });
  });

  const createdAt = formatDate(
    getDateByTimeZone(state.qnaDetail.createdAt),
    "yyyy/MM/dd HH:mm"
  );

  const renderNameWithEmail = () => {
    if (state.qnaDetail.user && state.qnaDetail.user.name !== null) {
      return `${state.qnaDetail.user.name} (${state.qnaDetail.user.email})`;
    } else if (state.qnaDetail.user) {
      return state.qnaDetail.user.email;
    }
  };

  return (
    <Container>
      <Row>
        <Title>문의/제안</Title>
      </Row>
      <Card>
        <Card.Header as="h5">{state.qnaDetail.title}</Card.Header>
        <Card.Body>
          <Table bordered>
            <tbody>
              <tr>
                <th>이름 (이메일)</th>
                <td>{renderNameWithEmail()}</td>
              </tr>
              <tr>
                <th>연락처</th>
                <td>{state.qnaDetail.user && state.qnaDetail.user.contact}</td>
              </tr>
              <tr>
                <th>접속 기기 정보</th>
                <td>{state.qnaDetail.user && state.qnaDetail.user.etc}</td>
              </tr>
              <tr>
                <th>작성 일시</th>
                <td>{createdAt}</td>
              </tr>
            </tbody>
          </Table>

          <ContentContainer>
            <Row>
              <strong>문의내용</strong>
            </Row>
            <Row>
              <Pre>{state.qnaDetail.content}</Pre>
            </Row>
          </ContentContainer>

          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label>
                <strong>답변내용</strong>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                name="answer"
                ref={register({ required: false })}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              저장
            </Button>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Link to="/qnas">목록으로 돌아가기</Link>
        </Card.Footer>
      </Card>
    </Container>
  );
};
