import React from "react";
import BootstrapTable from "react-bootstrap/Table";
import { Column as RtColumn, useTable, TableOptions } from "react-table";
import styled from "@emotion/styled";

import Pagination from "@/components/Table/Pagination";

const Thead = styled.thead`
  th {
    cursor: pointer;
    min-width: 50px;
  }
`;

export interface Table<T extends object = {}> extends TableOptions<T> {
  manualPagination: boolean;
  pageCount: number;
}

const Table: React.FC<{
  columns: RtColumn<any>[];
  currentPage: number;
  data: any[];
  gotoPage: (page: number) => void;
  nextPage: () => void;
  pageSize: number;
  pageCount: number;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
}> = ({
  columns,
  currentPage,
  data,
  gotoPage,
  nextPage,
  pageSize,
  pageCount,
  previousPage,
  setPageSize,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable({ columns, data });

  return (
    <>
      <BootstrapTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </Thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BootstrapTable>

      <Pagination
        canNextPage={currentPage < pageCount}
        canPreviousPage={currentPage > 1}
        nextPage={nextPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={currentPage - 1}
        pageSize={pageSize}
        previousPage={previousPage}
        setPageSize={setPageSize}
      />
    </>
  );
};

export type Column = RtColumn;
export default Table;
